<template>
  <div>
    <el-button v-if="$check('health-role-mgt-root_add')" type="primary" icon="el-icon-plus" @click="handleAdd">新 增</el-button>

    <el-table v-loading="tableLoading" :data="roleList">
      <el-table-column align="center" width="80" type="index" label="序号"></el-table-column>
      <el-table-column align="center" prop="name" label="角色名称" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" width="200" label="操作">
        <template slot-scope="{ row }">
          <div>
            <span v-if="$check('health-role-mgt-root_edit')" class="action-btn mr-24" @click="handleEdit(row)">修改</span>
            <span v-if="$check('health-role-mgt-root_delete')" class="action-btn danger-btn" @click="handleDelete(row)">删除</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :current-page="queryForm.pageNo"
      :page-size="queryForm.pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 50]"
      layout="total, sizes, prev, pager, next"
    ></el-pagination>
    <!-- 新增/编辑弹窗 -->
    <el-dialog
      width="530px"
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="handleClose"
    >
      <el-form ref="form" inline label-width="80px" :model="paramsForm" :rules="rules">
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="paramsForm.name" placeholder="请输入" style="width: 400px"></el-input>
        </el-form-item>
        <div v-loading="treeLoading" class="tree-wrapper">
          <el-tree
            ref="grantTreeRef"
            show-checkbox
            :check-on-click-node="true"
            :expand-on-click-node="false"
            :data="grantTree"
            :props="defaultProps"
            default-expand-all
            :default-checked-keys="paramsForm.grantMenuIdList"
            node-key="id"
          ></el-tree>
        </div>
      </el-form>
      <span slot="footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import {
  getRoleList,
  postRoleInfo,
  deleteRole,
  getRoleMenus,
  getGrantMenus
} from '@/api/general-user/role-management.js';

export default {
  data() {
    return {
      tableLoading: false,
      total: 0,
      queryForm: {
        pageNo: 1,
        pageSize: 10
      },
      roleList: [],
      title: '新增',
      dialogVisible: false,
      confirmLoading: false,
      paramsForm: {
        id: '',
        name: '',
        grantMenuIdList: []
      },
      rules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { max: 10, message: '长度在 10 个字符以内', trigger: 'blur' }
        ]
      },
      treeLoading: false,
      grantTree: [
        {
          title: 'web',
          id: '',
          children: []
        },
        {
          title: 'app',
          id: '',
          children: []
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'title'
      }
    };
  },
  created() {
    this.getRoleListData();
  },
  methods: {
    getRoleListData() {
      this.tableLoading = true;
      getRoleList(this.queryForm)
        .then(res => {
          this.total = res.data.total;
          this.roleList = res.data.records;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleSizeChange(size) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      this.getRoleListData();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getRoleListData();
    },
    async getGrantTreeData() {
      this.treeLoading = true;
      try {
        const res = await getGrantMenus();
        this.grantTree[0].children = res.data.webMenusTree;
        this.grantTree[1].children = res.data.appMenusTree;
      } finally {
        this.treeLoading = false;
      }
    },
    handleAdd() {
      this.getGrantTreeData();
      this.title = '新增';
      this.dialogVisible = true;
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        const grantMenuIdChildList = this.$refs.grantTreeRef.getHalfCheckedKeys();
        const grantMenuIdParentList = this.$refs.grantTreeRef.getCheckedKeys();
        const treeMenuIdList = [...grantMenuIdChildList, ...grantMenuIdParentList];
        if (treeMenuIdList.includes('')) {
          treeMenuIdList.shift();
        }
        if (treeMenuIdList.length === 0) {
          return this.$message.warning('请选择授权菜单权限');
        }
        this.postRoleInfoHandler(this.paramsForm, treeMenuIdList);
      });
    },
    postRoleInfoHandler(data, treeMenuIdList) {
      const { id } = data;
      const params = cloneDeep(this.paramsForm);
      params.grantMenuIdList = treeMenuIdList;
      this.confirmLoading = true;
      postRoleInfo(params)
        .then(res => {
          this.$message.success(`${id ? '编辑' : '新增'}角色成功！`);
          this.handleClose();
          if (!id) {
            this.queryForm.pageNo = 1;
            this.queryForm.pageSize = 10;
          }
          this.getRoleListData();
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.paramsForm.id = '';
      this.paramsForm.grantMenuIdList = [];
      // this.grantTree = [];
      this.$data.grantTree = this.$options.data().grantTree;
      this.dialogVisible = false;
    },
    handleDelete(row) {
      this.$confirm('是否确认删除该角色?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRoleHandler(row.id);
      });
    },
    deleteRoleHandler(id) {
      deleteRole({ id }).then(res => {
        const length = res.data.length;
        if (length === 0) {
          this.$message.success('删除角色成功！');
          this.queryForm.pageNo = 1;
          this.queryForm.pageSize = 10;
          this.getRoleListData();
        } else {
          this.$alert(
            `
              <p>删除该角色将会影响到以下用户，无法进行删除操作：</p>
              <span>${res.data.join('，')}</span>
            `,
            '提示',
            { dangerouslyUseHTMLString: true }
          );
        }
      });
    },
    handleEdit(row) {
      this.dialogVisible = true;
      this.title = '编辑';
      this.$nextTick(() => {
        this.paramsForm.id = row.id;
        this.paramsForm.name = row.name;
        this.getGrantTreeData().then(() => {
          this.getRoleMenusHandler(row.id);
        });
      });
    },
    getRoleMenusHandler(id) {
      this.treeLoading = true;
      getRoleMenus({ id })
        .then(res => {
          if (res.code === 200) {
            this.treeLoading = false;
            setTimeout(() => {
              res.data.forEach(value => {
                this.$refs.grantTreeRef.setChecked(value, true, false);
              });
            }, 200);
          }
        })
        .catch(() => {
          this.treeLoading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.tree-wrapper {
  box-sizing: border-box;
  max-height: 500px;
  // min-height: 500px;
  border: 1px solid #eee;
  padding: 16px 0;
  overflow: auto;

  .el-tree {
    box-sizing: border-box;
    padding: 0 16px;
    min-width: 100%;
    display: inline-block;
  }
}
</style>
