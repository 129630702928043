import request from '@/utils/request.js';

/**
 * 获取角色列表
 * @returns {Promise}
 */
export function getRoleList(params) {
  return request({
    url: '/traffic-construction/sysRole/page',
    method: 'get',
    params
  });
};

/**
 * 新增/编辑角色
 * @returns {Promise}
 */
export function postRoleInfo(data) {
  return request({
    url: '/traffic-construction/sysRole/addOrEdit',
    method: 'post',
    data
  });
};

/**
 * 删除角色
 * @returns {Promise}
 */
export function deleteRole(data) {
  return request({
    url: '/traffic-construction/sysRole/delete',
    method: 'post',
    data
  });
};
/**
 * 获取角色拥有菜单列表
 * @returns {Promise}
 */
export function getRoleMenus(params) {
  return request({
    url: '/traffic-construction/getRoleMenus',
    method: 'get',
    params
  });
};

/**
 * 获取菜单树（剔除管理员的）
 * @returns {Promise}
 */
export function getGrantMenus(params) {
  return request({
    url: '/traffic-construction/sysMenu/treeForStaffGrant',
    method: 'get',
    params
  });
};
